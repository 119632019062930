import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../../containers/Hosting/Navbar';

import Footer from '../../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../../components/seo';
import TopRibbon from '../../../containers/Hosting/TopRibbon';
import PropTypes from 'prop-types';
import Logo from 'common/src/assets/image/hosting/logo.svg';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import ContactMongo from '../../../containers/Hosting/ContactMongo/index';
import RaghuImage from 'common/src/assets/image/hosting/Team/Raghu.jpg';
import ShyamImage from 'common/src/assets/image/hosting/Team/shyam.png';
import BackgroundImage from 'common/src/assets/image/hosting/Background/back_circle 1.svg';

// import "./web.css";
const Webinarpage = ({ row, col, subTitle, title }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    const loc = window.location.pathname;
    if (loc.substr(loc.length - 1) === '/') {
      setUrl(loc.substring(0, loc.length - 1));
    } else {
      setUrl(loc);
    }
  }, []);
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        {/* <SEO
          title="Webinar"
          description="Rapid Acceleration Partners provides practical AI and Intelligent RPA solutions. Extract structured data from unstructured content. Book a demo here."
        /> */}
        <Helmet>
          <title>Achieving Peak Efficiencies in Mortgage Processing</title>
          <meta
            name="description"
            content="Achieving Peak Efficiencies in Mortgage Processing"
          />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper className="HomePage">
          <Wrapper>
            {/* ---------------------------------------------------------------- */}
            {/* --------------------------HEADER ITEM --------------------------- */}
            {/* ---------------------------------------------------------------- */}
            <div className="outer-holder" style={{ position: 'relative' }}>
              <img
                src={BackgroundImage}
                style={{ position: 'absolute', top: '150px', zIndex: '-9999' }}
              />
              <div className="container small-header">
                <div className="top-section">
                  <header className="header">
                    <div className="logo d-flex">
                      <Link className="logo-main" to="/">
                        <div className="mktoImg mktoGen" id="mkto_gen_imgLogo">
                          <img
                            className="mktoImg"
                            id="imgLogo"
                            mktoname="Logo Image"
                            src={Logo}
                            alt="RAP Logo"
                            title="RAP Logo"
                          />
                        </div>
                      </Link>
                    </div>
                  </header>
                  <div className="page-breadcrumb sectionShow">Webinar</div>
                  <div className="header-bg-mobile">
                    <h1
                      mktoname="Page Title"
                      id="pageTitle"
                      className="mktoString page-title"
                    >
                      Achieving Peak Efficiencies in Mortgage Processing
                    </h1>

                    <div
                      mktoname="Page When"
                      className="page-time d-flex sectionShow"
                      style={{ marginTop: '20px' }}
                    >
                      <i className="fa fa-clock-o"></i>
                      <span>Oct 20 / 11 AM - 12 PM CST</span>{' '}
                    </div>
                  </div>
                </div>
              </div>
              {/* ---------------------------------------------------------------- */}
              {/* --------------------------SPEAKER SECTION ITEM --------------------------- */}
              {/* ---------------------------------------------------------------- */}
              <div
                className="main-outside"
                style={{ minHeight: '890px', marginTop: '20px' }}
              >
                <div class="d-flex main">
                  <div class="side side-left d-flex">
                    <div class="speakers sectionShow">
                      <h2 class="page-def-title">Speakers</h2>
                      <div class="speakers-list d-flex speakertocenter">
                        {/* -------------------- */}
                        {/* ---- SPEAKER-------- */}
                        {/* -------------------- */}
                        <div class="speaker sectionShow">
                          <div class="speaker-image d-flex">
                            <div
                              class="mktoImg mktoGen"
                              id="mkto_gen_imgSpeaker1"
                            >
                              <a
                                href="https://www.linkedin.com/in/raghuramanramamurthy/"
                                target="_blank"
                              >
                                <img
                                  class="lpimg"
                                  id="imgSpeaker1"
                                  mktoname="Speaker 1: Image"
                                  src={RaghuImage}
                                />
                              </a>
                            </div>
                          </div>
                          <div class="speaker-info">
                            <div class="speaker-name">
                              Raghuraman Ramamurthy
                            </div>
                            <div class="speaker-from">Founder &amp; CEO</div>
                          </div>
                        </div>
                        {/* -------------------- */}
                        {/* ---- SPEAKER-------- */}
                        {/* -------------------- */}
                        <div class="speaker sectionShow">
                          <div class="speaker-image d-flex">
                            <div
                              class="mktoImg mktoGen"
                              id="mkto_gen_imgSpeaker2"
                            >
                              <a
                                href="https://www.linkedin.com/in/shyamkrishnan1/"
                                target="_blank"
                              >
                                <img
                                  class="lpimg"
                                  id="imgSpeaker2"
                                  mktoname="Speaker 2: Image"
                                  src={ShyamImage}
                                />
                              </a>
                            </div>
                          </div>
                          <div class="speaker-info">
                            <div class="speaker-name">Shyam Krishnan</div>
                            <div class="speaker-from">VP of Growth</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="page-text">
                      <div class="mktoText" id="pageText">
                        <p>
                          <span>
                            <span style={{ fontWeight: '600' }}>
                              About the webinar:
                            </span>
                          </span>
                          <span>
                            <span>
                              <br />
                            </span>
                          </span>
                        </p>
                        <p>
                          <span>
                            <span>
                              Mortgage processing is manually intensive.
                              Literally any step across mortgage processing is a
                              potential bottleneck. One of the main cause of
                              these inefficiencies are the complex forms and
                              tons of different types of documents involved in
                              the process. This is further complicated by
                              regulatory compliance requirements, disparate
                              systems, stakeholders and third party vendors. A
                              recent canon study states that mortgage companies
                              spend from $100,000 to $1 million and above on
                              processing and compliance errors.
                            </span>
                          </span>
                        </p>
                        <p>
                          <span>
                            <span>
                              Join Raghu and Shyam and learn how to achieve peak
                              efficiencies like never before with
                              Hyperautomation.
                            </span>
                          </span>
                        </p>
                        <p>
                          <span>
                            <span style={{ fontWeight: '600' }}>
                              Register now to learn learn:
                            </span>
                          </span>
                          <span>
                            <span>
                              <br />
                            </span>
                          </span>
                        </p>

                        <ul>
                          <li>
                            <span>
                              <span>How to achieve peak efficiencies.</span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                How to reduce at least 30% of operating costs.
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                How to achieve both with your existing systems
                                and resources.
                              </span>
                            </span>
                          </li>
                          {/* <li>
                          <span>
                            <span>how to gain speed and efficiency through&nbsp;staying agile</span>
                          </span>
                        </li>
                        <li>
                          <span>
                            <span>the quantifiable benefits of NN Group's RPA deployment</span>
                          </span>
                        </li>
                        <li>
                          <span face="arial, helvetica, sans-serif">
                            <span>the outlook for the group's automation journey</span>
                          </span>
                        </li> */}
                        </ul>
                        <p>
                          <span>
                            <span></span>
                          </span>
                          <br />
                        </p>
                        <p>
                          <span>
                            <span style={{ fontWeight: '600' }}>
                              Limited seats available!
                            </span>
                          </span>
                          <br />
                          <span>
                            <span>
                              <em>
                                Can’t attend? 
                                <a href="#contact-form"> Register</a> anyway and
                                we’ll send you a recording after the webinar!
                              </em>
                              <br />
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="page-image-side-top">
                    <div class="page-form" id="contact-form">
                      <ContactMongo
                        buttontext="Book My Slot"
                        formname="Webinar - Achieve peak efficiencies with frictionless mortgage processing"
                        formtitle="Register Now!"
                        redirect={true}
                        name={url}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------------------------------------------------------------- */}
            {/* --------------------------FOOTER ITEM--------------------------- */}
            {/* ---------------------------------------------------------------- */}

            <div class="footer">
              <div class="footer-container">
                <div class="logo-footer">
                  {/* <a href="#">
                    <img src={Logo} />
                  </a> */}
                </div>
                <hr />
                <div class="footer-social-networks">
                  <a
                    href="https://www.linkedin.com/company/rapid-acceleration-partners"
                    target="_blank"
                    className="footer-icons"
                  >
                    <i className="fa fa-linkedin-square"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/rapventures/"
                    target="_blank"
                    className="footer-icons"
                  >
                    <i className="fa fa-facebook-square"></i>
                  </a>
                  <a
                    href="https://twitter.com/rap_ventures"
                    target="_blank"
                    className="footer-icons"
                  >
                    <i className="fa fa-twitter-square"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/rapid_acceleration_partners/"
                    target="_blank"
                    className="footer-icons"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCMSXhWJFm3Vvp1Rmbfa54lw/"
                    target="_blank"
                    className="footer-icons"
                  >
                    <i className="fa fa-youtube-square"></i>
                  </a>
                </div>

                <div class="footer-copyright">
                  <span>
                    Copyright © 2022 Rapid Acceleration Partners, Inc.
                  </span>
                </div>
              </div>
            </div>
          </Wrapper>
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
Webinarpage.propTypes = {
  row: PropTypes.object,
};

Webinarpage.defaultProps = {};
export default Webinarpage;

const Wrapper = styled.section`
  #contact_section3 {
    background: #fff !important;
    box-shadow: 0 0 8px rgba(31, 57, 74, 0.25) !important;
  }
  * {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    background: #f7f7f7;
    position: relative;
  }
  strong {
    font-weight: 600;
  }
  img {
    max-width: 100%;
  }

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 600px;
    overflow: hidden;
    z-index: 10;
    background: url('https://start.uipath.com/rs/995-XLT-886/images/dot.png')
      repeat;
  }

  .main-header-bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -91px;
  }

  .line-separator {
    background: #58595b;
    width: 2px;
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
  }

  .rect {
    background: #e1e2e4;
    width: 192px;
    height: 64px;
    position: absolute;
    z-index: 10;
    top: 0;
  }
  .rect-top {
    height: 102px;
    width: 66px;
    left: 38%;
  }
  .rect-left {
    top: 325px;
    left: -43px;
  }
  .rect-center {
    top: 357px;
    left: calc((100vw - 1200px) / 2 + 540px);
  }

  .circles {
    position: relative;
    z-index: 10;
    display: block;
  }
  .circles:before {
    position: absolute;
    content: '';
    width: 64px;
    height: 64px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    left: calc((100vw - 1200px) / 2 - 64px - 100px);
    top: 149px;
    background: #e1e2e4;
  }
  .circles:after {
    position: absolute;
    content: '';
    width: 64px;
    height: 64px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    right: calc((100vw - 1200px) / 2 - 64px - 90px);
    top: 325px;
    background: #e1e2e4;
  }

  .d-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .main {
    position: relative;
    z-index: 100;
    flex-direction: row;
    justify-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    flex: 1 1 0;
    justify-content: space-between;
  }
  .container {
    position: relative;
    z-index: 100;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .top-section {
    width: 50%;
  }

  .header {
    padding-top: 40px;
  }
  .logo {
    align-items: stretch;
  }
  .logo-main {
    display: inline-block;
  }
  .logo-main img {
    max-width: 136px;
  }
  .logo-partner {
    display: inline-block;
    align-self: center;
  }
  .logo-partner img {
    max-width: 136px;
  }

  .page-breadcrumb {
    margin-top: 56px;
    margin-bottom: 8px;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
  }
  .page-title {
    font-size: 2.75rem;
    line-height: 3rem;
    margin: 8px 0;
  }
  .page-subtitle {
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin: 36px 0;
    font-weight: 400;
  }
  .page-time {
    font-weight: 700;
  }
  .page-time i {
    margin-right: 17px;
    font-size: 1.375rem;
    display: inline-block;
  }
  .page-time span {
    display: inline-block;
    line-height: 1.375rem;
    font-weight: 600;
  }

  h2.page-def-title {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .speakers .speaker-image {
    justify-content: center;
    align-items: center;
    max-width: 192px;
    max-height: 192px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .speakers .speaker-image .mktoGen {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    width: 100%;
    position: relative;
  }
  .speakers img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .speakers .speakers-list {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .speakers .speakers-list.speakertoleft {
    justify-content: flex-start;
  }
  .speakers .speakers-list.speakertocenter {
    justify-content: center;
  }
  .speakers .speaker-name {
    color: #273139;
    font-size: 1.125rem;
    text-align: center;
    margin-top: 16px;
  }
  .speakers .speaker-from {
    color: #273139;
    font-size: 0.875rem;
    text-align: center;
    margin-top: 4px;
  }
  .speakers .speaker {
    margin-bottom: 40px;
    padding: 0 5px;
    flex: 1 1 0;
    min-width: 200px;
    display: inline-block;
    max-width: 50%;
  }
  .speakers .speaker.sectionHide {
    display: none;
  }

  .page-text h2 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    margin-bottom: 24px;
  }
  .page-text h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .page-text p {
    margin: 0 0 2.25rem;
    line-height: 1.5rem;
  }
  .page-text ul {
    // list-style-image: url(images/bullet.png);
    list-style-position: outside;
    // list-style: none;
    // padding: 0;
    // margin: 0;
  }
  .page-text ul li {
    list-style-type: revert;
    margin-bottom: 18px;
    padding-left: 31px;
    position: relative;
    line-height: 1.5rem;
  }
  // .page-text ul li:before {
  //   content: "\e951";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   font-size: 1.375rem;
  // }

  .page-text table {
    margin: 0;
    padding: 0;
    line-height: 1.6em;
    border-spacing: 0;
  }
  .page-text table tr {
    margin: 0;
    padding: 0;
  }
  .page-text table tr td {
    border-bottom: 1px solid #e1e2e4;
    padding: 12px 0;
    min-width: 25px;
  }
  .page-text table tr td:first-child {
    padding-right: 30px;
    white-space: nowrap;
  }
  .page-text table tr td img {
    width: 80px;
    height: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    float: left;
    margin-right: 22px;
  }
  .page-text table tr td img::after {
    content: '';
    clear: both;
    display: table;
  }

  .spacer-1 {
    margin-bottom: 1rem;
  }
  .spacer-2 {
    margin-bottom: 2rem;
  }
  .spacer-3 {
    margin-bottom: 3rem;
  }

  .page-text-footer {
    position: relative;
    margin-top: 112px;
  }
  .page-text-footer .dotts {
    position: absolute;
    top: -48px;
    left: -48px;
    height: 128px;
    width: 128px;
    z-index: 1;
    background: url('https://start.uipath.com/rs/995-XLT-886/images/dot.png')
      repeat;
    /*background: linear-gradient(90deg, #f7f7f7 20px, transparent 1%) center, linear-gradient(#f7f7f7 20px, transparent 1%) center, #7b7b7b;background-size: 22px 22px;*/
  }
  .page-text-footer-container {
    z-index: 10;
    position: relative;
    line-height: 1.5rem;
    background: #f7f7f7;
  }

  .button {
    height: 48px;
    padding: 10px 56px 10px 24px;
    border: 0;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    text-decoration: none;
    min-width: 200px;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
  .button-default {
    background: #fa4616;
    color: #fff;
  }
  .button-default:after {
    content: '\e919';
    position: absolute;
    right: 24px;
    top: 0;
    font-size: 1.375rem;
    line-height: 48px;
  }

  .page-form {
    position: relative;
    // margin-top: 100px;
  }
  .page-form-container {
    background: #fff;
    padding: 48px 24px;
    box-shadow: 0 16px 32px -16px rgba(0, 0, 0, 0.2);
    z-index: 2;
    position: relative;
  }
  .page-form-container h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 0;
    padding: 0 12px;
    margin-bottom: 32px;
  }
  .page-form .dotts {
    position: absolute;
    bottom: -62px;
    right: -56px;
    height: 126px;
    width: 272px;
    z-index: 1;
    background: url('https://start.uipath.com/rs/995-XLT-886/images/dot.png')
      repeat;
    /*background: linear-gradient(90deg, #f7f7f7 14px, transparent 1%) center, linear-gradient(#f7f7f7 14px, transparent 1%) center, #c5c5c5;background-size: 16px 16px;*/
  }

  .page-image-robot {
    text-align: right;
    justify-content: flex-end;
    position: relative;
    max-width: 468px;
  }
  .page-image-robot-container {
    overflow: hidden;
    padding-top: 100%;
    position: relative;
    width: 100%;
  }
  .page-image-robot-container.make-round {
    border-radius: 50%;
  }
  .page-image-robot-container .mktoImg.mktoGen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .page-image-robot-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .image-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 688px;
  }

  .page-image-side-top {
    // position: absolute;
    padding-right: 2%;
    top: 150px;
    width: calc(44 / 100 * 1170px);
    z-index: 100;
    right: calc((100% - 1170px) / 2);
    padding-bottom: 60px;
  }

  .mktoForm .mktoButtonRow .mktoButtonWrap .mktoButton {
    height: 48px;
    padding: 10px 56px 10px 24px;
    border: 0;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    text-decoration: none;
    min-width: 200px;
    display: inline-block;
  }
  .mktoForm .mktoButtonRow .mktoButtonWrap .mktoButton:after {
    content: '\e919';
    position: absolute;
    right: 24px;
    top: 0;
    font-size: 1.375rem;
    line-height: 48px;
  }

  .side {
    width: 48%;
  }
  .side-left {
    flex-direction: column;
  }

  .logo-footer {
    order: 1;
  }
  .logo-footer svg {
    fill: red;
  }
  .logo-footer a {
    display: inline-block;
    width: 96px;
  }

  .footer {
    background: #111212;
    padding: 25px 15px 48px 15px;
    margin-top: 160px;
  }
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }
  .footer-container hr {
    width: 100%;
  }
  .footer hr {
    margin: 24px 0;
    padding: 0;
    width: 100%;
    height: 1px;
    background: #182027;
    border: 0;
    order: 3;
  }
  .footer-social-networks {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    order: 2;
    flex-grow: 1;
    align-items: center;
  }
  .footer-social-networks a {
    display: block;
    width: 20px;
    margin: 0 16px;
  }

  .footer-links {
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 1.375rem;
    order: 4;
  }
  .footer-links span {
    position: relative;
    display: inline-block;
  }
  .footer-links span:not(:first-child):before {
    content: '';
    position: absolute;
    height: 3px;
    width: 3px;
    background-color: #526069;
    border-radius: 50%;
    left: -5px;
    top: 11px;
    display: inline-block;
  }
  .footer-links span a {
    color: #526069;
    font-size: 0.75rem;
    margin: 0 5px;
    text-decoration: none;
    line-height: 1.375rem;
    position: relative;
  }
  .footer-copyright span {
    color: #526069;
    font-size: 0.75rem;
    line-height: 1.5rem;
    position: relative;
    display: inline-block;
  }
  .footer-copyright {
    color: #526069;
    font-size: 0.75rem;
    text-align: center;
    order: 5;
  }

  body .sectionHide {
    display: none;
  }

  .full-header .top-section {
    width: 100%;
  }
  .full-header .main-outside {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .full-header .main-outside .side {
    width: 100%;
  }
  .full-header .page-image-side-top {
    top: unset;
    position: relative;
    left: unset;
    right: unset;
    width: auto;
    flex: 1 1 0;
  }
  .full-header .main {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  @media screen and (max-width: 560px) {
    .speakers .speakers-list {
      flex-direction: column;
    }
    .speakers .speaker-name {
      text-align: left;
      margin-top: 0;
    }
    .speakers .speaker-from {
      text-align: left;
    }
    .speakers .speaker {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      text-align: left;
      margin-bottom: 24px;
      flex: 0 1 auto;
      max-width: 100%;
    }
    .speaker .speaker-image {
      width: 80px;
      margin-right: 15px;
    }
    .speaker .speaker-info {
      flex: 1 1 0;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      text-align: left;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1200px) {
    .logo {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
    }
    .logo-main {
      width: 120px;
    }
    .logo-main img {
      width: 120px;
      max-width: 100%;
    }
    .logo-partner {
      display: inline-block;
      width: 120px;
    }
    .logo-partner img {
      max-width: 100%;
    }

    .main {
      flex-direction: column;
      padding: 0;
    }
    .container {
      max-width: 768px;
    }

    .top-section {
      width: 100%;
    }

    .page-breadcrumb {
      max-width: 768px;
      margin: 30px auto 0 auto;
    }

    .page-image-robot-container {
      padding-top: 90%;
      width: 90%;
    }
    .image-over {
      max-width: 100%;
    }

    .page-title {
      font-size: 2rem;
      line-height: 2.5rem;
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
    }
    .page-subtitle {
      font-size: 1.25rem;
      line-height: 2rem;
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
    }
    .page-time {
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
    }

    .header {
      margin-top: 0;
      padding-top: 18px;
      max-width: 768px;
      margin-left: auto;
      margin-right: auto;
    }
    .header-bg {
      display: none;
    }
    .header-bg-mobile {
      // background: linear-gradient(90deg, #f7f7f7 16px, transparent 1%) center,
      //   linear-gradient(#f7f7f7 16px, transparent 1%) center, #c5c5c5;
      background-size: 18px 18px;
      padding: 0 15px 50px 15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    .page-text-footer .dotts {
      display: none;
    }
    .page-text-footer .spacer {
      display: none;
    }
    .page-form {
      margin-bottom: 126px;
      margin-top: 30px;
    }
    .page-form .dotts {
      right: 0;
    }

    h2.page-def-title {
      margin-top: 40px;
      margin-bottom: 16px;
    }

    .page-text table tr td:first-child {
      padding-right: 15px;
    }

    .side {
      width: 100%;
    }
    .side-left {
      flex-direction: column;
      padding: 0 15px;
    }
    .side-left .header-bg-mobile {
      order: 1;
    }
    .side-left .speakers {
      order: 3;
      max-width: 768px;
      margin: 0 auto 0 auto;
      width: 100%;
    }
    .side-left .page-text {
      order: 4;
      max-width: 768px;
      margin: 0 auto;
    }
    .side-left .page-text-footer {
      order: 5;
      max-width: 768px;
      margin: 0 auto;
    }
    .side-left .go-to-form {
      order: 2;
      max-width: 768px;
      margin: 0px auto 0 0;
    }

    .side-right {
      max-width: 768px;
      margin: 0 auto;
    }

    .go-to-form .button:after {
      content: '\e917';
      font-size: 1rem;
    }

    .page-image-side-top {
      // position: relative;
      width: 100%;
      padding: 0 15px;
      right: unset;
      max-width: 768px;
      margin: 30px auto 0 auto;
      top: auto;
    }

    .full-header .main-outside {
      flex-direction: column;
    }

    .footer {
      margin-top: 64px;
    }
    .footer hr {
      order: 2;
    }
    .footer-container {
      flex-direction: column;
      max-width: 600px;
      margin: 0 auto;
    }
    .footer-copyright {
      margin-top: 12px;
    }
    .footer-links {
      margin-top: 80px;
    }
    .footer-social-networks {
      justify-content: space-between;
    }
    .footer-social-networks a {
      margin: 0;
    }
  }
  .footer-icons {
    color: #595a5c;
    font-size: 25px;
  }
`;
